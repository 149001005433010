<template>
  <section class="mx-2 mb-2">
    <b-form-row>
      <!-- Per Page -->
      <b-col class="d-flex align-items-center">
        <b-form-group
          label="Per Page"
          label-cols="5"
          label-align="left"
          label-size="sm"
          label-for="per-page"
          class="text-nowrap d-block d-sm-inline-flex align-items-sm-center mb-md-0 mr-1"
        >
          <b-form-select
            id="per-page"
            inline
            size="sm"
            :options="pageOptions"
            :value="perPage"
            @input="handlePerPageInput"
            @change="$emit('change-per-page', $event)"
          />
        </b-form-group>
      </b-col>

      <!-- Entries -->
      <b-col class="d-flex align-items-center justify-content-center">
        <span
          v-if="total"
          class="text-muted"
        >
          Showing {{ from }} to {{ to }} of {{ total }} entries
        </span>
      </b-col>

      <!-- Pagination -->
      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end">
        <b-pagination
          first-number
          last-number
          class="mb-0"
          prev-class="prev-item"
          next-class="next-item"
          :value="currentPage"
          :total-rows="total"
          :per-page="perPage"
          @input="$emit('update:current-page', $event)"
          @change="$emit('change-pagination', $event)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>

          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-form-row>
  </section>
</template>

<script>
import {
  BFormRow, BCol, BFormGroup, BFormSelect, BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BFormRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  props: {
    from: {
      type: [Number, String],
      default: 0,
    },
    to: {
      type: [Number, String],
      default: 10,
    },
    total: {
      required: true,
      type: [Number, String],
    },
    currentPage: {
      type: [Number, String],
      default: 1,
    },
    perPage: {
      type: [Number, String],
      default: 10,
    },
    pageOptions: {
      type: Array,
      default: () => [5, 10, 25, 50, 100],
    },
  },
  created() {
    const perPageCached = localStorage.getItem('perPage')

    if (perPageCached) {
      this.$emit('update:per-page', perPageCached)
    }
  },
  methods: {
    handlePerPageInput(value) {
      this.$emit('update:per-page', value)
      localStorage.setItem('perPage', value)
    },
  },
}
</script>

<style scoped>

</style>
