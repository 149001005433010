var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',_vm._b({ref:"table",attrs:{"show-empty":"","items":_vm.items,"fields":_vm.fields,"striped":_vm.$attrs.striped || true,"responsive":_vm.$attrs.responsive || 'md',"per-page":_vm.$attrs['per-page'] || (_vm.pagination ? _vm.metaData.perPage : 0),"current-page":_vm.$attrs['current-page'] || (_vm.pagination ? _vm.metaData.currentPage : 1)},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1)]},proxy:true},{key:"empty",fn:function(){return [_c('h6',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$attrs.emptyMessage || 'No data found.')+" ")])]},proxy:true},{key:_vm.linkCell,fn:function(ref){
var item = ref.item;
return [_c('b-link',{on:{"click":function($event){$event.preventDefault();return _vm.$emit('link-clicked', item)}}},[_vm._v(" "+_vm._s(item[_vm.linkCellKey])+" ")])]}},_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"cell(delete)",fn:function(row){return [_c('div',{staticClass:"d-flex justify-content-end"},[_vm._t("actions",null,null,row),_c('b-button',{staticStyle:{"white-space":"nowrap"},attrs:{"text":"","size":"sm","variant":"flat-danger"},on:{"click":function($event){_vm.$store.commit('app/deleteModal/UPDATE_DELETE_MODAL', Object.assign({}, {title: ((_vm.deleteModal.deleteButtonText || _vm.deleteButtonText) + " " + (_vm.title(_vm.itemName))),
            text: _vm.deleteModal.text,
            itemName: _vm.title(row.item.name),
            endpoint: ((_vm.endpoints.delete) + "/" + (row.item.id)),
            deleteButtonText: _vm.deleteButtonText,
            afterDelete: function () {
              _vm.items.splice(_vm.items.findIndex(function (v) { return v.id === row.item.id; }), 1)
              if (_vm.metaData.total) { _vm.metaData.total-- }
              _vm.$emit('item-deleted', row.item)
            }},
            // Override default properties. If function pass row data.
            (typeof _vm.deleteModal === 'function') ? _vm.deleteModal(row) : _vm.deleteModal))}}},[_vm._v(" "+_vm._s(_vm.deleteButtonText)+" ")])],2)]}}],null,true)},'b-table',_vm.$attrs,false),[_vm._l((Object.keys(_vm.$slots)),function(slot){return _vm._t(slot,null,{"slot":slot})})],2),(_vm.pagination)?_c('Pagination',_vm._g({attrs:{"from":_vm.metaData.from || _vm.entries.from,"to":_vm.metaData.to || _vm.entries.to,"total":_vm.metaData.total || _vm.entries.total,"per-page":_vm.metaData.perPage,"current-page":_vm.metaData.currentPage,"page-options":_vm.metaData.pageOptions},on:{"update:perPage":function($event){return _vm.$set(_vm.metaData, "perPage", $event)},"update:per-page":function($event){return _vm.$set(_vm.metaData, "perPage", $event)},"update:currentPage":function($event){return _vm.$set(_vm.metaData, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.metaData, "currentPage", $event)}}},_vm.$listeners)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }