<template>
  <div>
    <b-table
      ref="table"
      show-empty
      :items="items"
      :fields="fields"
      :striped="$attrs.striped || true"
      :responsive="$attrs.responsive || 'md'"
      :per-page="$attrs['per-page'] || (pagination ? metaData.perPage : 0)"
      :current-page="$attrs['current-page'] || (pagination ? metaData.currentPage : 1)"
      v-bind="$attrs"
    >
      <template #table-busy>
        <div class="text-center">
          <b-spinner variant="primary" />
        </div>
      </template>

      <template #empty>
        <h6 class="text-center">
          {{ $attrs.emptyMessage || 'No data found.' }}
        </h6>
      </template>

      <!-- Link Cell -->
      <template v-slot:[linkCell]="{ item }">
        <b-link @click.prevent="$emit('link-clicked', item)">
          {{ item[linkCellKey] }}
        </b-link>
      </template>

      <!-- Pass on all named slots -->
      <slot
        v-for="slot in Object.keys($slots)"
        :slot="slot"
        :name="slot"
      />

      <!-- Pass on all scoped slots -->
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>

      <!-- Delete Cell -->
      <template #cell(delete)="row">
        <div class="d-flex justify-content-end">
          <slot
            name="actions"
            v-bind="row"
          />

          <b-button
            text
            size="sm"
            variant="flat-danger"
            style="white-space: nowrap"
            @click="$store.commit('app/deleteModal/UPDATE_DELETE_MODAL', {
              title: `${deleteModal.deleteButtonText || deleteButtonText} ${title(itemName)}`,
              text: deleteModal.text,
              itemName: title(row.item.name),
              endpoint: `${endpoints.delete}/${row.item.id}`,
              deleteButtonText,
              afterDelete: () => {
                items.splice(items.findIndex(v => v.id === row.item.id), 1)
                if (metaData.total) metaData.total--
                $emit('item-deleted', row.item)
              },
              // Override default properties. If function pass row data.
              ...(typeof deleteModal === 'function') ? deleteModal(row) : deleteModal,
            })"
          >
            {{ deleteButtonText }}
          </b-button>
        </div>
      </template>
    </b-table>

    <Pagination
      v-if="pagination"
      :from="metaData.from || entries.from"
      :to="metaData.to || entries.to"
      :total="metaData.total || entries.total"
      :per-page.sync="metaData.perPage"
      :current-page.sync="metaData.currentPage"
      :page-options="metaData.pageOptions"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import {
  BTable, BButton, BLink, BSpinner,
} from 'bootstrap-vue'
import Pagination from '@/components/Pagination.vue'

import { title } from '@/@core/utils/filter'

export default {
  components: {
    BTable,
    BButton,
    BLink,
    BSpinner,
    Pagination,
  },
  inheritAttrs: false,
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      required: false,
      type: Array,
      default: () => [],
    },
    itemName: {
      required: true,
      type: String,
    },
    linkCellKey: {
      type: String,
      default: 'name',
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    endpoints: {
      type: Object,
      default: () => ({
        delete: '',
      }),
    },
    deleteModal: {
      type: [Object, Function],
      default: () => ({}),
    },
    deleteButtonText: {
      type: String,
      default: 'Delete',
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    metaData: {
      type: Object,
      default: () => ({
        perPage: 10,
        currentPage: 1,
      }),
    },
  },
  computed: {
    entries() {
      // Ref will not exist until table is rendered.
      const itemsCount = this.$refs.table ? this.$refs.table.paginatedItems.length : 0

      return {
        from: this.metaData.perPage * (this.metaData.currentPage - 1) + (itemsCount ? 1 : 0),
        to: this.metaData.perPage * (this.metaData.currentPage - 1) + itemsCount,
        total: this.items.length, // "items" are needed to re-render function when table is loaded and get correct paginatedItems from $ref.
      }
    },
    linkCell() {
      return `cell(${this.linkCellKey})`
    },
  },
  mounted() {
    if (this.showDelete) {
      this.fields.push({
        key: 'delete',
        label: '',
      })
    }
  },
  methods: {
    title,
  },
}
</script>

<style scoped>

</style>
